import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {getMobileToken} from 'src/app/utils/utils.static';

@Injectable({
  providedIn: 'root'
})
export class FilesWebservice {
  private readonly root = `${environment.envApiUrl}/files`;

  private readonly httpMobile: HttpClient;

  constructor(private readonly http: HttpClient,
              private readonly httpBackend: HttpBackend) {
    this.httpMobile = new HttpClient(httpBackend);
  }

  postUploadFile(idProgram: number, formData: FormData) {
    return this.http.post(`${this.root}/file/${idProgram}`, formData);
  }

  getFilesInfo(idProgram: number) {
    return this.http.get(`${this.root}/getFilesInfo/${idProgram}`);
  }

  downloadFile(fileName: string, idProgram: number) {
    return this.http.get(`${this.root}/download-file/${idProgram}/${fileName}`, {
      responseType: 'blob'
    });
  }

  deleteFile(fileName: string, idProgram: number) {
    return this.http.delete(`${this.root}/delete/?fileName=${fileName}&idProgram=${idProgram}`);
  }

  deleteUserFile(idUser: number, fileName: string, idProgram: number) {
    return this.http.delete(
      `${this.root}/delete/${idUser}?fileName=${fileName}&idProgram=${idProgram}`
    );
  }

  renameFile(idProgram: number, oldFileName: string, newFileName: string) {
    return this.http.post(
      `${this.root}/rename-file/${idProgram}?oldFileName=${oldFileName}`,
      newFileName
    );
  }

  getAnnexesFiles(idYoung) {
    return this.http.get(`${this.root}/annexes/${idYoung}`);
  }

  saveAnnexesFiles(idYoung, files) {
    return this.http.post(`${this.root}/annexes/${idYoung}`, files);
  }

  deleteAnnexe(idYoung, fileName, pDocument) {
    return this.http.delete(
      `${this.root}/annexes/${idYoung}?fileName=${fileName}&&pDocument=${pDocument}`
    );
  }

  downloadInterview(idInterview: number, idYoung: number, filename: string[]) {
    return this.http.post(`${this.root}/entretien/${idInterview}/${idYoung}`, filename);
  }

  upload(analyzedFile: FormData) {
    return this.http.post(`${this.root}/upload`, analyzedFile);
  }

  downloadFileSigned(idEvent: number, idOccurrence: number) {
    return this.http.get<Blob>(`${this.root}/download-signed-file/${idEvent}/${idOccurrence}`, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  downloadVisit(idVisit: number) {
    const token = getMobileToken();
    if (token) {

      return this.httpMobile.get(`${this.root}/download-visit-file/${idVisit}`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        responseType: 'blob'
      });
    } else {
      return this.http.get(`${this.root}/download-visit-file/${idVisit}`, {responseType: 'blob'});
    }
  }
}
